<template>
	<div v-if="info" class="home_body">
		<div class="swiper one-swiper" style="height: 538px">
			<el-carousel trigger="click" @change="oneSwiperChange" :interval="5000" height="538px" arrow="always">
				<el-carousel-item v-for="item in info.carousel" :key="item.id">
					<img @click="linkClick(item.url)" :src="item.image"/>
				</el-carousel-item>
				<div class="indicator flex-center" v-if="info.carousel.length != 1">
          <span
						v-for="(item, index) in info.carousel"
						:key="item.id"
						:style="index == oneSwiperIndex ? 'background:#e50114' : ''"
					></span>
				</div>
			</el-carousel>
		</div>

		<div class="content flex-center">
			<img src="../../assets/image/duoweiguanwang.png" class="duoweiguanggao_body" @click="toDuoWei">
		</div>
		<!--		协会公告 新闻动态 直播-->
		<div class="announcementUpdates content flex-space-between">
			<div class="left">
				<div class="left_header flex-space-between">
					<div class="tabs_body flex">
						<div class="tabs_body_item pointer_s  flex-center" v-for="(item,index) in info.news" :key="index"
								 :class="newsIndex==index ? 'choose' : '' " @click="announcementClick(index)">
							{{ item.name }}
						</div>
					</div>
					<img class="look_more pointer_s" src="../../assets/image/img/look_more.png"
							 @click="moreClick(1,info.news[newsIndex])">
				</div>
				<div class="left_body">
					<div class="left_body_item flex pointer_s" v-for="(item,index) in info.news[newsIndex]?.child" :key="index"
							 @click="newDetail(item,info.news[newsIndex])">
						<div class="data_box">
							<span class="data_num">{{ item.day }}</span>
							<span class="year_num">{{ item.date }}</span>
						</div>
						<div class="info_box flex-direction-column ">
							<div class="info_box_name one-line">
								{{ item.title }}
							</div>
							<div class="info_box_info two-line">{{ item.content }}</div>
							<!--							<div class="info_box_info two-line" v-html="item.content"></div>-->
						</div>
					</div>
					<Lack
						v-if="info.news[newsIndex]?.child.length == 0"
						text="暂无内容"
						minHeight="464px"
						imgWidth="102px"
						imgHeight="68px"
						:imgSrc="require('../../assets/image/img/15.png')"
					></Lack>
				</div>
			</div>
			<div class="right">
				<!--				<div class="right_header flex">-->
				<!--					<img class="line_s" src="../../assets/image/img/line_s.png">-->
				<!--					<span class="header_title flex">正在直播：<span-->
				<!--						style="color: #212F82">门球单打锦标赛全国巡回北京站</span></span>-->
				<!--				</div>-->
				<div class="img_body" @click="toVideoDetail({name:'',url:info.broadcast.video,})">
					<img :src="info.broadcast.image" class="img">
					<!--					<div class="img_title">门球单打锦标赛全国巡回北京站</div>-->
					<img class="img_title" src="../../assets/image/img/video_open.png">
				</div>
				<Lack
					v-if="info.carousel.length == 0"
					text="暂无内容"
					minHeight="373px"
					imgWidth="102px"
					imgHeight="68px"
					:imgSrc="require('../../assets/image/img/15.png')"
				></Lack>
			</div>
		</div>

		<!--		视频和图片-->
		<div class="video_body">
			<div class="video_body66 content flex-direction-column">
				<div class="video_header">
					<div class="video_header_body flex">
						<span class="video_title">精彩时刻</span>
						<div class="video_tabs pointer_s" v-for="(item,index) in videoList"
								 :class="videoIndex==index ? '' : 'unchoose'"
								 :key="index" @click="videoClick(index)">
							{{ item.name }}
						</div>
					</div>
					<div class="look_more_body" @click="moreClick(2,{id:0})">
						<img class="look_more pointer_s" src="../../assets/image/img/look_more.png">
					</div>
				</div>
				<div class="videoORPng_body " v-show="videoIndex==0">
					<div class="video_img_body" v-if="info.video[0]" @click="toVideoDetail(info.video[0])">
						<img :src="info.video[0]?.image" class="video_img">
						<img class="video_img_open" src="../../assets/image/img/video_open.png">
						<div class="title_body">
							<div class="marl20">
									<span class="one-line"
												style="width: 350px">{{ info.video[0].name }}</span>
								<span style="font-size: 14px">{{ info.video[0].date }}</span>
							</div>
						</div>
					</div>
					<div class="video_body2">
						<div class="video_img_body2" v-if="info.video[1]" @click="toVideoDetail(info.video[1])">
							<img :src="info.video[1]?.image" class="video_img">
							<img class="video_img_open" src="../../assets/image/img/video_open.png">
							<div class="title_body">
								<div class="marl20">
									<span class="one-line"
												style="width: 350px">{{ info.video[1].name }}</span>
									<span style="font-size: 14px">{{ info.video[1].date }}</span>
								</div>
							</div>
						</div>
						<div class="video_img_body2" v-if="info.video[2]" @click="toVideoDetail(info.video[2])">
							<img :src="info.video[2]?.image" class="video_img">
							<img class="video_img_open" src="../../assets/image/img/video_open.png">
							<div class="title_body">
								<div class="marl20">
									<span class="one-line"
												style="width: 350px">{{ info.video[2].name }}</span>
									<span style="font-size: 14px">{{ info.video[2].date }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="video_img_body" v-if="info.video[3]" @click="toVideoDetail(info.video[3])">
						<img :src="info.video[3]?.image" class="video_img">
						<img class="video_img_open" src="../../assets/image/img/video_open.png">
						<div class="title_body">
							<div class="marl20">
									<span class="one-line"
												style="width: 350px">{{ info.video[3].name }}</span>
								<span style="font-size: 14px">{{ info.video[3].date }}</span>
							</div>
						</div>
					</div>
					<Lack
						v-if="info.video.length == 0"
						text="暂无内容"
						minHeight="540px"
						imgWidth="102px"
						imgHeight="68px"
						:imgSrc="require('../../assets/image/img/15.png')"
					></Lack>
				</div>


				<div class="videoORPng_body " v-show="videoIndex==1">
					<div class="video_img_body">
						<el-image
							class="video_img"
							fit="cover"
							:src="info.image[0]?.src"
							:preview-src-list="info.imageC">
						</el-image>
					</div>
					<div class="video_body2">
						<div class="video_img_body2">
							<el-image
								class="video_img"
								fit="cover"
								:src="info.image[1]?.src"
								:preview-src-list="info.imageC">
							</el-image>
						</div>
						<div class="video_img_body2">
							<el-image
								class="video_img"
								fit="cover"
								:src="info.image[2]?.src"
								:preview-src-list="info.imageC">
							</el-image>
						</div>
					</div>
					<div class="video_img_body">
						<el-image
							class="video_img"
							fit="cover"
							:src="info.image[3]?.src"
							:preview-src-list="info.imageC">
						</el-image>
					</div>
					<Lack
						v-if="info.image.length == 0"
						text="暂无内容"
						minHeight="540px"
						imgWidth="102px"
						imgHeight="68px"
						:imgSrc="require('../../assets/image/img/15.png')"
					></Lack>
				</div>
			</div>
		</div>
		<!--		News updates 新闻动态-->
		<div class="newsUpdates content flex-space-between">
			<div class="newsUpdates_item" v-for="(item,index) in info.list">
				<div class="newsUpdates_item_header flex-space-between">
					<div class="flex">
						<img src="../../assets/image/img/difang.png" v-if="index==0" class="title_icon">
						<img src="../../assets/image/img/jizhanshu.png" v-if="index==1" class="title_icon">
						<img src="../../assets/image/img/yiyu.png" v-if="index==2" class="title_icon">
						<div class="title_text">{{ item.name }}</div>
					</div>
					<div class="look_more_body" @click="moreClick(3,item)">
						<img class="look_more pointer_s" src="../../assets/image/img/look_more.png">
					</div>
				</div>

				<div class="newsUpdates_body list2 flex-direction-column" style="margin-top: 0">
					<div class="list_box flex-direction-column">
						<div
							style="padding: 0 5px"
							class="list_item flex-space-between"
							v-for="item2 in item.child"
							:key="item2.id"
							@click="newDetail(item2,item)"
						>
							<div class="list_item_left flex">
								<span></span>
								<p style="width: 360px" class="one-line">{{ item2.title }}</p>
							</div>
						</div>
						<Lack
							v-if="item.child.length == 0"
							text="暂无内容"
							minHeight="370px"
							imgWidth="102px"
							imgHeight="68px"
							:imgSrc="require('../../assets/image/img/15.png')"
						></Lack>
					</div>
				</div>
			</div>
		</div>

		<!-- 赞助商及合作伙伴 -->
		<div class="sponsor" v-if="info.sponsor.length != 0">
			<div class="sponsor_body">
				<div class="sponsor-title-one flex">
					<img class="line_s" src="../../assets/image/img/line_s.png">赞助商及合作伙伴
				</div>
				<div class="flex" v-for="(i, v) in info.sponsor" :key="v">
					<div class="sponsor-title-two two-line" v-if="i.data.length">{{ i.name }}</div>
					<div class="sponsor-official flex-wrap" style="justify-content: center">
						<div class="sponsor-item flex-center" v-for="ii in i.data" :key="ii.id" @click="linkClick(ii.url)">
							<img :src="ii.image"/>
						</div>
					</div>
				</div>
			</div>
		</div>

<!--		@click="directSeedingDia=true"-->
		<div class="floating-sidebar pointer_s" >
			<div class="rili_s_body">
				<img class="rili_s" src="../../assets/image/img/zhibo.png">
				<span class="text_s">直播</span>
			</div>
			<div  class="rili_s_body" style="margin-top: 40px" @click="toActive">
				<img class="rili_s" src="../../assets/image/img/jiangbei.png">
				<span class="text_s">运动排名</span>
			</div>
		</div>
		<!--		直播-->
		<el-dialog
			class="live_broadcast"
			:show-close="false"
			:append-to-body="true"
			:visible.sync="directSeedingDia"
			width="800px"
			:before-close="handleClose">
			<div style="position: relative" v-if="directSeedingDia">

				<i class="el-icon-close close_png" @click="directSeedingDia=false"></i>
				<iframe
					:src="iframeUrl"
					width="100%"
					height="600"
					frameborder="0"
					allowfullscreen
				></iframe>
			</div>
		</el-dialog>
		<!--	视频-->
		<el-dialog
			:title="videoUrl.name"
			:visible.sync="dialogVisible"
			width="800px"
			:append-to-body="true">
			<!--			<video v-if="dialogVisible" style="width: 760px" :src="videoUrl.url" :poster="videoUrl.image" loop controls></video>-->

			<video v-if="dialogVisible" style="width: 760px" :src="videoUrl.url" loop controls></video>
			<!--			videoUrl-->
		</el-dialog>
	</div>
</template>

<script>
import {postHome} from '@/service/api/index'
import Lack from '../../components/lack/lack.vue'

export default {
	components: {Lack},
	data() {
		return {
			videoUrl: {
				url: '',
				image: ''
			},
			dialogVisible: false,
			value1: '',
			isVisible: false,
			videoList: [
				{
					name: '视频',
					id: 1,
					choose: true,
				}, {
					name: '图片',
					id: 2,
					choose: false,
				}
			],
			info: {
				news: [],
				broadcast: {},
				carousel: [],
				image: [],
				list: [],
				sponsor: [],
				video: [],
				imageC: []
			},
			newsIndex: 0,
			videoIndex: 0,
			oneSwiperIndex: 0,
			serviceList: [],
			directSeedingDia: false,
			iframeUrl: 'https://uniapp.dcloud.net.cn/',
		}
	},
	mounted() {
		sessionStorage.setItem('navbarIndex', 0)
		this.postHome()
	},

	methods: {
		toActive(){
			this.$store.state.listId = '36'
			//头部的导航栏id
			sessionStorage.setItem('navbarId', '36')
			this.$router.replace({
				name: 'integral',
				params: {
					info: JSON.stringify({
						id: '36',
						status:3
					})
				}
			})
		},
		toDuoWei() {
			window.open('https://www.do-win.com/', '_blank')
		},
		toVideoDetail(item) {
			if (!item.url) {
				this.$message.warning('视频异常请联系管理员')
				return
			}
			this.dialogVisible = true
			this.videoUrl = item
			// this.$router.replace({
			// 	name: 'vdetail',
			// 	params: {
			// 		info: item
			// 	}
			// })
		},
		handleClose() {

		},


		announcementClick(index) {
			this.newsIndex = index
		},
		videoClick(index) {
			this.videoIndex = index
		},

		newDetail(item, fItem) {
			this.$store.state.listId = fItem.id
			//头部的导航栏id
			sessionStorage.setItem('navbarId', fItem.id)
			this.$router.replace({
				name: 'pudetail2',
				params: {
					info: JSON.stringify({
						id: item.id,
						status: item.status
					})
				}
			})
		},
		postHome() {
			postHome().then((res) => {
				this.info = res.msg
				this.info.imageC = res.msg.image.map(item => {
					return item.src
				})
			})
		},
		oneSwiperChange(index) {
			this.oneSwiperIndex = index
		},
		moreClick(status, item) {
			if (status == 1 || status == 3) {
				this.$store.state.listId = item.id
				sessionStorage.setItem('navbarId', item.id)
				this.$router.replace({
					name: 'plist',
					params: {
						info: JSON.stringify({
							id: item.id,
							type: 1,
							status: item.status
						})
					}
				});
			} else if (status == 2) {
				this.$router.replace({
					name: 'vwrc',
					params: {
						info: JSON.stringify({
							videoIndex: this.videoIndex,
							type: 2,
							item: 0
						})
					}
				})
			}
		},
		linkClick(url) {
			if (url) {
				window.open(url, '_blank')
			}
		},
	}
}
</script>

<style lang="less" scoped>
.home_body {
	background-color: #FFFFFF;
	margin-bottom: -20px;
	margin-top: 90px;
}

.name {
	width: 750px;
}

.three-swiper {
	/deep/ .el-carousel__arrow--left {
		display: none !important;
	}

	/deep/ .el-carousel__arrow--right {
		display: none !important;
	}
}

.one-swiper {
	/deep/ .el-carousel__arrow--left {
		width: 70px;
		height: 70px;
		left: 200px;
		font-size: 25px;
	}

	/deep/ .el-carousel__arrow--right {
		width: 70px;
		height: 70px;
		right: 200px;
		font-size: 25px;
	}

	/deep/ .el-carousel__button {
		display: none;
	}
}

.two-swiper {
	/deep/ .el-carousel__arrow--left {
		display: none !important;
	}

	/deep/ .el-carousel__arrow--right {
		display: none !important;
	}

	/deep/ .el-carousel__button {
		width: 12px;
		height: 12px;
		border-radius: 50%;
	}
}

.content {
	.left {
		width: 940px;
	}

	.right {
		width: 400px;
	}
}

.swiper {
	width: 100%;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.indicator {
		width: 100%;
		position: absolute;
		bottom: 40px;
		z-index: 10;

		span {
			width: 37px;
			height: 6px;
			background: rgba(255, 255, 255);
			border-radius: 3px;
			margin-right: 10px;
		}
	}
}


.sponsor {
	//background: #fff;
	background-image: url('../../assets/image/img/bjt.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 70px 0;
	//margin-bottom: 14px;
	.left {
		height: 33px;
		font-size: 24px;
		font-weight: bold;
		line-height: 33px;
		width: 141px;
		margin-left: 80px;
	}

	.sponsor_body {
		width: 1360px;
		margin: 0 auto;
	}

	.sponsor-title-one {
		//height: 56px;
		font-size: 20px;
		//line-height: 56px;
		color: #333333;
		margin-bottom: 20px;

		.line_s {
			margin-right: 10px;
			width: 11px;
			height: 22px;
			object-fit: fill;
		}
	}

	.sponsor-title-two {
		font-size: 18px;
		width: 160px;
		line-height: 42px;
		color: #212F82;
		margin-top: 22px;
		padding-right: 20px;
		text-align: center;
	}

	.link-wrap {
		width: auto !important;
		padding: 0 48px !important;
	}

	.sponsor-official {
		.sponsor-item {
			cursor: pointer;
			width: 267px;
			height: 147.8px;
			border: 1px solid #dddddd;
			border-radius: 5px;
			margin-right: 22px;
			margin-top: 22px;

			img {
				width: 100%;
				height: 100%;
				border-radius: 5px;

				object-fit: cover;
			}
		}

		.link {
			width: 153px;
			align-items: center;
			justify-content: center;
			text-align: center;
			// margin-right: 96px;
			margin-bottom: 20px;
			cursor: pointer;
			text-align: center;

			&:nth-child(6n) {
				margin-right: 0;
			}

			img {
				width: 150px;
				height: 52px;
				object-fit: cover;
				border-radius: 50%;
			}

			span {
				margin-top: 5px;
				width: 153px;
				height: 20px;
				font-size: 14px;
			}
		}
	}
}

.duoweiguanggao_body {
	width: 580px;
	object-fit: contain;
	margin-top: 40px
}

.announcementUpdates {
	padding: 40px 20px 70px;

	.left {
		width: 766px;
		height: 449px;
		background: #FFFFFF;
		border-radius: 5px 5px 5px 5px;
		border: 1px solid #E8E8E8;

		.left_header {
			padding: 0 20px;
			height: 75px;
			border-bottom: 1px solid #E8E8E8;

			.tabs_body {

				.tabs_body_item {
					width: 135px;
					height: 40px;
					background: #FFFFFF;
					border-radius: 45px;
					color: #333333;
					font-size: 20px;
					line-height: 28px;
					margin-right: 20px;
				}

				.choose {
					background: #212F82;
					color: #FFFFFF;
				}
			}

			.look_more {
				width: 90px;
				height: 22px;
			}
		}

		.left_body {
			padding: 20px;
			height: 333px;
			overflow-y: auto;

			.left_body_item {
				height: 68px;
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}

				.data_box {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 68px;
					height: 68px;
					background: #FFFFFF;
					border-radius: 10px;
					border: 1px solid #212F82;

					.data_num {
						font-weight: bold;
						font-size: 23px;
						color: #212F82;
					}

					.year_num {
						font-weight: 400;
						font-size: 13px;
						color: #212F82;
						transform: scale(0.85);
					}
				}

				.info_box {
					width: 620px;
					margin-left: 20px;
					display: flex;
					flex-direction: column;

					.info_box_name {
						font-weight: 500;
						font-size: 16px;
						color: #212F82;
						line-height: 19px;

						&:hover {
							font-weight: bold;
						}
					}

					.info_box_info {
						margin-top: 10px;
						max-height: 38px;
						font-weight: 400;
						font-size: 14px;
						color: #5A5A5A;
						line-height: 19px;
						text-align: left;
					}
				}
			}
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		width: 564px;
		//padding-top: 59px;
		height: 449px;

		.right_header {
			.line_s {
				width: 11px;
				height: 29px;
			}

			.header_title {
				font-size: 24px;
				color: #333333;
				line-height: 28px;
				text-align: left;
				margin-left: 10px;
			}
		}

		.img_body {
			position: relative;
			width: 494px;
			height: 449px;
			border-radius: 5px 5px 5px 5px;
			//margin-top: 32px;

			.img {
				width: 494px;
				height: 449px;
				border-radius: 5px 5px 5px 5px;
				object-fit: cover;
			}

			.img_title {
				position: absolute;
				left: 230px;
				top: 200px;
				width: 62px;
				height: 62px;
			}
		}
	}
}

.video_body {
	width: 100%;
	//height: 785px;
	background-image: url('../../assets/image/img/bg_png_body.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 60px 0;

	.video_body66 {
		.video_header {
			//height: 112px;
			border-bottom: 1px solid #AAAAAA;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.video_header_body {
				height: 50px;

				.video_title {
					font-size: 20px;
					color: #333333;
					line-height: 28px;
					margin-right: 120px;
				}

				.video_tabs {
					width: 75px;
					height: 50px;
					background: #212F82;
					font-weight: 500;
					font-size: 17px;
					color: #FFFFFF;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 30px;
				}

				.unchoose {
					background: none;
					color: #333333;
				}
			}

			.look_more_body {
				height: 58px;
				display: flex;
				justify-content: center;
				align-items: center;

				.look_more {
					width: 90px;
					height: 22px;
				}
			}

		}

		.videoORPng_body {
			display: flex;

			margin-top: 54px;

			.video_img_body {
				position: relative;
				width: 440px;
				height: 550px;

				.video_img {
					width: 440px;
					height: 550px;
					object-fit: cover;
				}

				.video_img_open {
					position: absolute;
					top: 40%;
					right: 175.5px;
					width: 62px;
					height: 62px;
				}

				.title_body {
					width: 440px;
					height: 200px;
					display: flex;
					align-items: flex-end;
					padding-bottom: 20px;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 44%, rgba(0, 0, 0, 0.6) 100%);
					position: absolute;
					//left: 27px;
					bottom: 0px;
					font-size: 16px;
					color: #FFFFFF;
				}
			}

			.marl20 {
				margin-left: 20px;
			}

			.video_body2 {
				height: 550px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				margin: 0 20px;

				.video_img_body2 {
					position: relative;
					width: 440px;
					height: 265px;

					.video_img {
						width: 440px;
						height: 265px;
						object-fit: cover;
					}

					.video_img_open {
						position: absolute;
						top: 40%;
						right: 175.5px;
						width: 62px;
						height: 62px;
					}

					.title_body {
						width: 440px;
						height: 200px;
						background: linear-gradient(180deg, rgba(0, 0, 0, 0) 44%, rgba(0, 0, 0, 0.6) 100%);
						position: absolute;
						display: flex;
						align-items: flex-end;
						padding-bottom: 20px;
						bottom: 0px;
						font-size: 16px;
						color: #FFFFFF;
					}
				}
			}

		}
	}
}

.newsUpdates {
	padding: 70px 0;

	.newsUpdates_item {
		display: flex;
		flex-direction: column;
		border: 1px solid #E8E8E8;
		height: 480px;

		.newsUpdates_item_header {
			width: 440px;
			height: 60px;
			background: linear-gradient(90deg, #c5cbee 0%, rgba(33, 47, 130, 0.05) 100%);

			.title_icon {
				margin-left: 20px;
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}

			.title_text {
				font-size: 20px;
				color: #212F82;
				line-height: 28px;
			}

			.look_more_body {
				height: 58px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 20px;

				.look_more {
					width: 90px;
					height: 22px;
				}
			}
		}

		.newsUpdates_body {
			padding: 15px;

		}
	}

}
.floating-sidebar {
	position: fixed;
	top: 650px;
	right: 0;
	width: 86px;
	height: 240px;
	transition: left 0.3s;
	z-index: 99;
	border-radius:  20px 0 0 20px;
	background-color: #212F82;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	.rili_s_body{
		margin-left: 6px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.rili_s{
			width: 30px;
			height: 30px;
		}
		.text_s {
			font-weight: 500;
			font-size: 16px;
			height: 30px;
			line-height: 30px;
			color: #FFFFFF;
			margin-top: 5px;
		}
	}

}


.is-selected {
	color: #1989FA;
}

.live_broadcast {

	/deep/ .el-dialog {
		background-color: transparent !important; //弹出层透明
		box-shadow: none !important; /* 可能还需要移除阴影 */
	}

	/deep/ .el-dialog__header {
		padding: 0;
	}

	/deep/ .el-dialog__body {
		padding: 0;
	}

}

.close_png {
	position: absolute;
	top: 10px;
	right: -54px;
	color: #FFFFFF;
	font-size: 30px;
	font-weight: bold;
}

.list2 {
	width: 100%;
	margin-top: 12px;

	.list_box {
		height: 400px;
		overflow-y: auto;
		overflow-x: hidden;

		.list_item {
			line-height: 39px;
			height: 39px;
			font-size: 16px;
			padding: 0px 23px 0px 24px;
			cursor: pointer;

			&:hover {
				//background: #fff9fa;

				.list_item_left {
					font-weight: bold;

					span {
						background: #212F82;
					}

					p {
						color: #212F82;
					}
				}

				.list_item_right {
					color: #212F82;
				}
			}

			.list_item_left {
				span {
					width: 4px;
					height: 4px;
					background: #9A9A9A;
					border-radius: 50%;
					margin-right: 6px;
				}

				p {
					// width: 250px;
				}
			}

			.list_item_right {
				color: #999999;
			}
		}
	}

	.line {
		margin: 10px 22px 10px 21px;
		border-bottom: 1px dashed #eee;
	}
}
</style>
